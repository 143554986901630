<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: "XPack",
  }
</script>

<style lang="scss">
</style>
